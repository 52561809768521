
import { Component, Prop, Vue } from 'vue-property-decorator';

import { Promotion } from '@/types/Promotion';

import getPromotions from '@/requests/Promotions/getPromotions/getPromotions';

import Item from '@/components/Promotions/Item.vue';

@Component({
  components: {Item}
})
export default class ItemsList extends Vue {
  @Prop() promotions: Promotion[];
}
