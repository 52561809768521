
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Banner extends Vue {
  scrolled: boolean = false;

  mounted() {
    window.addEventListener('scroll', this.onScroll);
  }

  onScroll(e) {
    const windowTop = e.target.documentElement.scrollTop;
    if (windowTop > 0) this.scrolled = true;
  }
}
