
import { Component, Vue } from 'vue-property-decorator';

import TextHeader from '@/components/Layout/TextHeader.vue';
import Banner from '@/components/Promotions/Banner.vue';
import PromoItems from '@/components/Promotions/ItemsList.vue';
import LoadingScreen from '@/components/Layout/LoadingScreen.vue';
import Breadcrumbs from '@/components/Layout/Breadcrumbs.vue';

@Component({
  components: {
    TextHeader,
    Banner,
    PromoItems,
    LoadingScreen,
    Breadcrumbs,
  },
})
export default class Promotions extends Vue {
  created() {
    this.$setPageTitle('Потайная Лавка Рубика | DOTALOOT');
  }
  async mounted() {
    this.$store.dispatch('promotions/fetch');
  }

  get promotions() {
    return this.$store.state.promotions.promotions;
  }

  get isLoading() {
    return this.$store.state.promotions.isLoading;
  }
}
