import Axios from 'axios';

export default async function usePromotion(promotionId: number): Promise<{error?: string}> {
    if (process.env.NODE_ENV === 'development') {
        return {};
    }

    const response = await Axios.post('/api/promotions/use', {
        id: promotionId
    });
    const {error} = response.data;

    return {error};
}
