
import { Component, Prop, Vue } from 'vue-property-decorator';

import { Promotion } from '@/types/Promotion';

import Button from '@/components/Buttons/Button.vue';
import { getCaseValuablePrice } from '@/helpers';
import CaseImage from '@/components/Case/CaseImage.vue';
import usePromotion from '@/requests/Promotions/usePromotion/usePromotion';
import { ENABLED_COUNTRIES } from '@/constants/enabledCountries';
import { Country } from '@/types/PaymentCountry';

@Component({
  components: {CaseImage, Button},
})
export default class Item extends Vue {
  @Prop() promo: Promotion;
  blockRequest: boolean = false;

  created() {
    this.$store.dispatch('refill/fetch');
  }

  async redeemPromotion() {
    try {
      if (this.blockRequest) return;

      this.blockRequest = true;

      if (!this.user)
        return this.$notify({
          group: 'notification',
          duration: 5500,
          type: 'warning',
          title: 'Заклинание',
          text: 'Пожалуйста авторизуйтесь, чтобы использовать заклинание!',
        });

      const {error} = await usePromotion(this.promo.id);
      if (error) {
        return this.$notify({
          group: 'notification',
          duration: 5500,
          type: 'warning',
          title: 'Заклинание',
          text: error,
        });
      }

      this.$store.dispatch('modal/toggleModal', {
        name: 'refill',
        data: {amount: this.promo.price, name: this.promo.name},
      });
    } catch (e) {
      console.error(e);
      this.$notify({
        group: 'notification',
        duration: 5500,
        type: 'error',
        title: 'Заклинание',
        text: 'Неизвестная ошибка! Попробуйте позже :(',
      });
    } finally {
      this.blockRequest = false;
    }
  }

  openMana() {
    this.$store.dispatch('modal/toggleModal', {name: 'mana'});
  }

  get displayCurrency() {
    console.log(this.user?.country ?? 'other');
    return ENABLED_COUNTRIES.find(
        (country: Country) => country.code === (this.user?.country ?? 'other')
    ).currency;
  }

  coinsToDisplayCurrency(coins: number) {
    const rate = this.$store.state.refill.rates[this.displayCurrency];
    if (!rate) return null;

    const fractionDigits = ['USD', 'EUR'].includes(this.displayCurrency) ? 2 : 0;
    const addition = ['UAH', 'KZT'].includes(this.displayCurrency) ? 2 : 0;

    return Number((coins / rate).toFixed(fractionDigits)) + addition;
  }

  get user() {
    return this.$store.getters['user/isAuthorized']
        ? this.$store.state.user
        : null;
  }

  get profit() {
    return this.coinsToDisplayCurrency(
        this.promo.cases.reduce(
            (a, b) => a + getCaseValuablePrice(b.price),
            0
        )
    );
  }
}
